<template>
  <el-dialog
    :title="operateType === 1 ? '新增' : '编辑'"
    :visible.sync="dialogDis"
    width="30%"
    :before-close="handleClose"
    open="opened"
  >
    <el-form
      :model="formInline"
      label-width="100px"
      class="demo-form-inline dialog"
      :rules="rules"
      ref="form"
    >
      <el-form-item label="姓名" prop="name">
        <el-input v-model="formInline.name" placeholder="请输入姓名"></el-input>
      </el-form-item>
      <el-form-item label="年龄" prop="age">
        <el-input v-model="formInline.age" placeholder="请输入年龄"></el-input>
      </el-form-item>
      <el-form-item label="性别" prop="sex">
        <el-select v-model="formInline.sex" placeholder="请选择性别">
          <el-option label="男" value="男"></el-option>
          <el-option label="女" value="女"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="所在班级" prop="classid">
        <el-select v-model="formInline.classid" placeholder="请选择班级">
          <el-option label="三年级一班" :value="2"></el-option>
          <el-option label="三年级二班" :value="1"></el-option>
          <el-option label="三年级三班" :value="4"></el-option>
          <el-option label="三年级传说班" :value="3"></el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="handleClose">取 消</el-button>
      <el-button type="primary" @click="confirm">确定提交</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { insertStuApi, updateStuApi } from '../../api/studentapi'
export default {
  props: {
    visible: { type: Boolean, default: false },
    operateType: { type: Number, default: 1 },
    rowData: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data () {
    return {
      dialogDis: false,
      formInline: {
        classid: null,
        name: '',
        age: null,
        sex: '女'
      },
      student_id: null,
      rules: {
        classid: [{ required: true, message: '请选择班级', trigger: 'blur' }],
        name: [{ required: true, message: '请输入名字', trigger: 'blur' }],
        age: [{ required: true, message: '请输入年龄', trigger: 'blur' }],
        sex: [{ required: true, message: '请选择性别', trigger: 'blur' }]
      }
    }
  },

  watch: {
    visible (newval, oldval) {
      this.dialogDis = newval
    },
    rowData: {
      handler (newval, oldval) {
        if (newval) {
          this.student_id = this.rowData.s_id
          this.formInline.age = this.rowData.s_age
          this.formInline.name = this.rowData.s_name
          this.formInline.sex = this.rowData.s_sex
          this.formInline.classid = this.rowData.c_id
        } else {
          this.formInline.age = null
          this.formInline.name = ''
          this.formInline.sex = null
          this.formInline.classid = '女'
        }
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    handleClose () {
      this.dialogDis = false
      this.resetForm()
      this.$emit('handclose', this.formInline)
    },
    async addApi (args) {
      const { data } = await insertStuApi({ ...args })
      if (data.code === 200) {
        this.handleClose()
      }
    },
    async modifyApi (args) {
      const { data } = await updateStuApi({
        ...args
      })
      if (data.code === 200) {
        this.handleClose()
      }
    },
    confirm () {
      this.$refs.form.validate(valid => {
        if (valid) {
          if (this.operateType === 2) {
            this.modifyApi({ ...this.formInline, student_id: this.student_id })
          } else {
            this.addApi(this.formInline)
          }
        } else {
          return false
        }
      })
    },
    resetForm () {
      this.$refs.form.resetFields()
    }
  }
}
</script>

<style lang="less" scoped>
// ::v-deep .el-dialog {
//   min-width: 50%;
// }
.dialog {
  ::v-deep .el-input__inner {
    // width: 300px;
  }
}
::v-deep .el-dialog__footer {
  padding-right: 15%;
}
</style>
